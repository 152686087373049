import React from "react"
import RegionSectorReport from "../components/Report/regionSectorReport"
import Layout from "../components/Base/Layout"

export default function categoryReportTemplate({ pageContext }) {
  return (
    <Layout>
      <RegionSectorReport slug={pageContext.slug} />
    </Layout>
  )
}
